<template>
  <div class="teams-tab">
    <section class="section">
      <div class="columns mb-0">
        <div class="column">
          <h1 class="title is-medium is-marginless">
            Your Team
          </h1>
          <h1 class="subtitle is-marginless">
            Invite co-hosts and assign game licenses
          </h1>
        </div>
      </div>

      <div class="section mt-2">
        <b-notification
          :active="showError"
          @close="closeError"
          type="is-danger"
          >{{ showError ? error.message : "" }}</b-notification
        >
        <div
          class="my-3 is-size-5 has-text-grey has-text-centered"
          v-if="!teamAccount"
        >
          <b-icon icon="account-multiple"></b-icon>
          You don't have a team yet.
          <br />
          <p class="mt-2 is-size-6">
            Contact us at
            <b>hello@zensimu.com</b> if several persons from your organization
            need to host sessions.<br />We will provide a quote based on the
            number of instructor seats needed.
          </p>
        </div>
        <div
          v-if="teamAccount"
          class="profile-info-bloc"
          style="position: relative;"
        >
          <div class="columns mb-0 is-vcentered">
            <div class="column is-relative">
              <h2 class="title is-4 mb-5">
                <em v-if="teamAccount.name"> {{ teamAccount.name }}</em>
                <em class="has-text-grey" v-if="!teamAccount.name"
                  >Team name</em
                >
                <a
                  class="has-text-success ml-2"
                  @click="updateName"
                  v-if="
                    currentUser &&
                      teamAccount.members &&
                      teamAccount.members[currentUser.uid] &&
                      teamAccount.members[currentUser.uid].role === 'admin'
                  "
                >
                  <b-tooltip label="Edit team name" type="is-dark">
                    <b-icon class="ml-1" size="is-small" icon="pencil"></b-icon>
                  </b-tooltip>
                </a>
              </h2>
              <b-button
                v-if="
                  currentUser &&
                    teamAccount.members &&
                    teamAccount.members[currentUser.uid] &&
                    teamAccount.members[currentUser.uid].role === 'admin'
                "
                type="is-info"
                rounded
                @click="openUpdateModal"
                :disabled="
                  !availLicenses.totAvail || availLicenses.totAvail < 0
                "
                icon-left="plus"
                >Invite team member</b-button
              >
            </div>
            <div class="column is-6">
              <div
                class="columns is-multilined"
                style="justify-content: flex-end;"
              >
                <div
                  class="column is-6"
                  v-for="gg in availLicenses.games"
                  :key="`gt-${gg.type}`"
                >
                  <b>{{ gameTypes[gg.type].name }} licenses:</b>
                  <b-progress
                    class="my-2"
                    :type="
                      gameTypes[gg.type].classColor
                        ? gameTypes[gg.type].classColor
                        : 'is-info'
                    "
                    :value="(100 * gg.countInstructors) / gg.totLicenses"
                    size="is-small is-very-small"
                  ></b-progress>
                  <div class="columns is-size-7">
                    <div class="column">
                      Assigned:
                      <b>{{ gg.countInstructors }}</b>
                    </div>
                    <div class="column has-text-right">
                      Tot: <b>{{ gg.totLicenses }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <b-table
              :data="membersTab"
              :mobile-cards="false"
              :row-class="row => !row.uid && 'is-italic'"
            >
              <b-table-column v-slot="props" label="Name">
                {{ props.row.name | capitalize({ onlyFirstLetter: true }) }}
                <b v-if="currentUser && props.row.uid === currentUser.uid"
                  >(me)</b
                >
              </b-table-column>
              <b-table-column v-slot="props" label="Email">
                {{ props.row.email }}
              </b-table-column>
              <b-table-column label="Role">
                <template v-slot:header="{ column }">
                  {{ column.label }}
                  <b-tooltip
                    label="Admins can update plans, billing information and assign/remove game licenses"
                    type="is-dark"
                    append-to-body
                    size="is-small"
                    multilined
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  <span>
                    {{ props.row.role | capitalize }}
                    <span class="is-size-7" v-if="!props.row.uid"
                      >(Invite sent)</span
                    >
                  </span>
                </template>
              </b-table-column>
              <b-table-column label="Game Licenses">
                <template v-slot:header="{ column }">
                  {{ column.label }}
                  <b-tooltip
                    label="Types of games this user can host"
                    type="is-dark"
                    append-to-body
                    size="is-small"
                    multilined
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                </template>
                <template v-slot="props">
                  <figure
                    v-for="gt in props.row.games"
                    :key="`gtimg-${gt}`"
                    class="mr-2 image is-24x24 is-inline-block"
                  >
                    <img :src="gameTypes[gt].logo" />
                  </figure>
                </template>
              </b-table-column>
              <b-table-column v-slot="props" custom-key="quickbuttons">
                <div class="has-text-right">
                  <b-tooltip
                    label="Public instructor page"
                    type="is-dark"
                    class="ml-2"
                  >
                    <b-button
                      tag="router-link"
                      target="_blank"
                      rel="noreferrer"
                      :to="`/user/${props.row.uid}`"
                      type="is-info is-light"
                      size="is-small"
                      icon-left="open-in-new"
                      v-if="props.row.uid"
                    >
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    label="Edit member"
                    type="is-dark"
                    class="ml-2"
                    v-if="canEdit(props.row)"
                  >
                    <b-button
                      type="is-success is-light"
                      size="is-small"
                      icon-left="pencil"
                      @click="openUpdateModal(props.row)"
                    >
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    label="Remove team member"
                    type="is-dark"
                    v-if="canRemove(props.row)"
                    class="ml-2"
                  >
                    <b-button
                      type="is-danger is-light"
                      size="is-small"
                      icon-left="delete"
                      @click="removeMember(props.row)"
                    >
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </section>
    <b-modal :active.sync="showMemberModal" has-modal-card>
      <div class="member-modal-wrapper modal-card small-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">
            {{
              modalType === "edit"
                ? `Edit member: ${updateMember.name || updateMember.email}`
                : `Invite new member`
            }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="showMemberModal = false"
          ></button>
        </header>
        <section class="modal-card-body pt-2 pb-6">
          <form v-if="updateMember" id="update-member-form" ref="memberform">
            <div v-if="modalType === 'add'" class="mt-1 mb-2 has-text-centered">
              <b>He/She will receive an invitation via email.</b>
            </div>
            <b-field label="Email*" v-if="modalType === 'add'">
              <b-input
                v-model.trim="updateMember.email"
                type="email"
                required
              ></b-input>
            </b-field>

            <b-field label="Role">
              <b-field>
                <b-select
                  v-model="updateMember.role"
                  :disabled="updateMember.uid === currentUser.uid"
                >
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                </b-select>
              </b-field>
              <p class="control pl-3">
                Only admins can manage team members, assign game licenses, view
                and update billing information.
              </p>
            </b-field>
            <b-field label="Game licenses">
              <b-checkbox
                v-model="updateMember.games"
                v-for="gt in availLicenses.games"
                :key="`editlicgt-${gt.type}`"
                :native-value="gt.type"
                :disabled="
                  (!updateMember.uid ||
                    !teamAccount.members[updateMember.uid] ||
                    !teamAccount.members[updateMember.uid].games.includes(
                      gt.type
                    )) &&
                    !gt.avail
                "
              >
                {{ gameTypes[gt.type].name }}
              </b-checkbox>
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot buttons is-centered">
          <b-button
            rounded
            type="is-grey"
            size="is-medium"
            @click="showMemberModal = false"
            >Cancel</b-button
          >
          <b-button
            rounded
            form="update-member-form"
            type="is-success"
            tag="input"
            native-type="submit"
            size="is-medium"
            :value="modalType === 'edit' ? 'Edit member' : 'Add member'"
            @click.prevent="modalType === 'edit' ? editMember() : addMember()"
          ></b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "teamsTab",
  computed: {
    ...mapGetters([
      "error",
      "loading",
      "showError",
      "currentUser",
      "teamAccount",
      "gameTypes"
    ]),
    membersTab() {
      let tab = [];
      if (!this.teamAccount || !this.teamAccount.members) {
        return tab;
      }
      Object.keys(this.teamAccount.members).forEach(uid => {
        tab.push({
          ...this.teamAccount.members[uid],
          uid: uid,
          games: this.teamAccount.members[uid].games
            ? this.teamAccount.members[uid].games.filter(
                gt =>
                  this.teamAccount.plans &&
                  this.teamAccount.plans[gt] &&
                  this.teamAccount.plans[gt].active
              )
            : []
        });
      });
      if (this.teamAccount.awaiting && this.teamAccount.awaiting.length) {
        this.teamAccount.awaiting.forEach(amemb => {
          tab.push(amemb);
        });
      }
      return tab.sort((a, b) => {
        return a && a.role ? a.role.localeCompare(b.role) : 0;
      });
    },
    availLicenses() {
      let obj = {
        totAvail: 0,
        totAssigned: 0,
        totInstructors: 0,
        totLicenses: 0,
        games: []
      };
      if (
        !this.teamAccount ||
        !this.teamAccount.plans ||
        !this.teamAccount.members ||
        !Object.keys(this.teamAccount.members).length
      ) {
        return obj;
      }
      Object.entries(this.teamAccount.plans).forEach(([gameType, planData]) => {
        if (planData.active) {
          let countMembers = Math.max(
            1,
            Object.values(this.teamAccount.members).filter(
              memb => memb.games && memb.games.includes(gameType)
            ).length +
              (this.teamAccount.awaiting && this.teamAccount.awaiting.length
                ? this.teamAccount.awaiting.filter(
                    amemb => amemb.games && amemb.games.includes(gameType)
                  ).length
                : 0)
          );
          obj.totAssigned += countMembers;
          let gameLicenses = Math.max(planData.totLicenses || 0, 1);
          let addAvail = Math.max(0, gameLicenses - countMembers);
          obj.totLicenses += gameLicenses;
          obj.games.push({
            type: gameType,
            totLicenses: gameLicenses,
            countInstructors: countMembers,
            avail: addAvail
          });
        }
      });
      obj.totInstructors =
        Object.values(this.teamAccount.members).length +
        (this.teamAccount.awaiting ? this.teamAccount.awaiting.length : 0);
      obj.totAvail =
        obj.totLicenses - Math.max(obj.totAssigned, obj.totInstructors);
      return obj;
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    removeMember(memb) {
      this.$buefy.dialog.confirm({
        title: "Remove user",
        type: "is-danger",
        confirmText: "Remove",
        size: "is-medium",
        message: `Are you sure you want to remove ${memb.name ||
          memb.email} from the team ? He/She will not be able to host game sessions any more.`,
        onConfirm: () => {
          this.$store.dispatch("removeTeamMember", {
            teamName: this.teamAccount.name,
            teamId: this.teamAccount.id,
            userEmail: memb.email,
            ...(memb.uid && {
              userId: memb.uid
            })
          });
        }
      });
    },
    openUpdateModal(member) {
      this.showMemberModal = true;
      this.modalType = member && member.uid ? "edit" : "add";
      this.updateMember = {
        uid: null,
        name: "",
        email: "",
        games: [
          ...this.availLicenses.games
            .filter(gt => gt.avail > 0)
            .map(gt => gt.type)
        ],
        role: "member",
        ...(member && { ...member })
      };
    },
    updateName() {
      this.$buefy.dialog.prompt({
        message: "Update the team name :",
        size: "is-medium",
        inputAttrs: {
          value: this.teamAccount.name,
          required: true
        },
        trapFocus: true,
        onConfirm: value => {
          this.$store.dispatch("updateTeam", {
            id: this.teamAccount.id,
            obj: { name: value }
          });
        }
      });
    },
    editMember() {
      let isValid = this.$refs.memberform.checkValidity();
      if (isValid) {
        this.$store
          .dispatch("updateTeam", {
            id: this.teamAccount.id,
            obj: { [`members.${this.updateMember.uid}`]: this.updateMember }
          })
          .then(() => {
            this.showMemberModal = false;
          });
      }
    },
    addMember() {
      let isValid = this.$refs.memberform.checkValidity();
      if (isValid) {
        this.$store
          .dispatch("addTeamMember", {
            teamName: this.teamAccount.name,
            teamId: this.teamAccount.id,
            ...this.updateMember,
            userEmail: this.updateMember.email.toLowerCase()
          })
          .then(() => {
            this.showMemberModal = false;
          });
      }
    },
    canRemove(member) {
      return (
        this.teamAccount.members[this.currentUser.uid] &&
        this.teamAccount.members[this.currentUser.uid].role === "admin" &&
        member.email !== this.currentUser.email &&
        (!member.uid || member.uid !== this.currentUser.uid)
      );
    },
    canEdit(member) {
      return (
        this.teamAccount.members[this.currentUser.uid] &&
        this.teamAccount.members[this.currentUser.uid].role === "admin" &&
        member.uid
      );
    }
  },
  data() {
    return {
      showMemberModal: false,
      modalType: "edit",
      updateMember: {
        uid: null,
        name: "",
        email: "",
        games: [],
        role: "member"
      }
    };
  }
};
</script>
<style lang="scss">
.profile-tab {
  .profile-info-bloc {
  }
}
</style>
